import {
    Button,
    VStack,
    useBreakpointValue,
    Flex,
    Text,
    Textarea,
    FormControl,
    FormLabel,
    Input,
    Stack,
} from '@chakra-ui/react';
import {
    Email,
    Location,
    Phone,
    Clock
} from "../Config/icon";

export default function Contact() {
    return (
        <>
            <Stack>
                <Flex
                    w={'full'}
                    h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                    backgroundImage={
                        'url(https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)'
                    }
                    backgroundSize={'cover'}
                    backgroundPosition={'center center'}>
                    <VStack
                        w={'full'}
                        justify={'center'}
                        px={useBreakpointValue({ base: 4, md: 8 })}
                        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
                            <Text
                                color={'white'}
                                fontWeight={700}
                                lineHeight={1.2}
                                borderBottomWidth={0.5}
                                borderWidth={1}
                                p={2}
                                fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
                                Contacting Triadic
                            </Text>
                        </Stack>
                    </VStack>
                </Flex>
            </Stack>
            <Stack minH={'65vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'md'}>
                        <Text
                            fontSize={useBreakpointValue({ base: '1xl', md: '2xl', lg: '2xl', sm: '2xl' })}
                            fontWeight={700}>
                            Let's Start a Conversation
                        </Text>
                        <Flex>
                            <Email size={'28px'} />
                            <Text px={'2'}>contact@triadic.com</Text>
                        </Flex>
                        <Flex>
                            <Location size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>Rajkot, Gujarat</Text>
                        </Flex>
                        <Flex>
                            <Phone size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>+91 9099090708</Text>
                        </Flex>
                        <Flex>
                            <Clock size={'28px'} paddingRight={'1'} />
                            <Text px={'2'}>Monday - Saturday 9AM to 5PM (Sunday Close)</Text>
                        </Flex>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Stack direction={{ base: 'column', md: 'row' }}>
                        <Flex p={8} flex={1} align={'center'} justify={'center'}>
                            <Stack spacing={4} w={'full'} maxW={'md'}>
                                <FormControl id="name">
                                    <FormLabel>Name</FormLabel>
                                    <Input type="text" placeholder="Name" />
                                </FormControl>
                                <Flex justify={'center'}>
                                    <FormControl id="email" paddingRight={'1'}>
                                        <FormLabel>Email</FormLabel>
                                        <Input type="email" placeholder="Email" />
                                    </FormControl>
                                    <FormControl id="phone">
                                        <FormLabel>Phone No.</FormLabel>
                                        <Input type="text" placeholder="Phone No." />
                                    </FormControl>
                                </Flex>
                                <FormControl id="name">
                                    <FormLabel>Your Mesasge</FormLabel>
                                    <Textarea
                                        placeholder="Write your Message Here"
                                        size="sm"
                                    />
                                </FormControl>
                                <Stack>
                                    <Button leftIcon={<Email size={'18px'} />} colorScheme="teal" variant="solid">
                                        Send Message!
                                    </Button>
                                </Stack>
                            </Stack>
                        </Flex>
                    </Stack>
                    {/* <Image
                        alt={'Login Image'}
                        objectFit={'cover'}
                        src={
                            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
                        }
                    /> */}
                </Flex>
            </Stack>
        </>
    );
}
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    Button,
    useColorModeValue, 
    useBreakpointValue
} from '@chakra-ui/react';
import { useHistory } from "react-router-dom";
import {
    ShoppingCart,
    Mobile,
    Website,
    Services
} from '../../Config/icon';

const Feature = ({ text, icon, iconBg }) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function HomeServices() {

    const history = useHistory();
    function handleClick(path) {
        history.push(path)
    }

    return (
        <Container maxW={'7xl'} py={12}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Text
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize={'sm'}
                        bg={useColorModeValue('blue.50', 'blue.900')}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                        What We Do
                    </Text>
                    <Heading>Our Service Stack</Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        Our team is your trusted partner in offering the best suitable solutions for your business.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue('gray.100', 'gray.700')}
                            />
                        }>
                        <Feature
                            icon={<Icon as={ShoppingCart} color={'yellow.500'} size="18px" />}
                            iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                            text={'E-Commerce Solution'}
                        />
                        <Feature
                            icon={<Icon as={Mobile} color={'green.500'} size="18px" />}
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'Mobile Application Development'}
                        />
                        <Feature
                            icon={<Icon as={Website} color={'purple.500'} size="18px" />}
                            iconBg={useColorModeValue('purple.100', 'purple.900')}
                            text={'Web Development'}
                        />
                    </Stack>
                    <Flex justifyContent={useBreakpointValue({ sm: 'center', md: 'center', lg: 'flex-end' })}>
                        <Button colorScheme="teal" onClick={() => handleClick("services")} leftIcon={<Services size="18px" />} variant="solid">
                            More Services
                        </Button>
                    </Flex>
                </Stack>
                <Flex>
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    );
}
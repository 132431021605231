import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    useColorModeValue,
    HStack,
    VStack,
    ListItem,
    ListIcon,
    List,
} from '@chakra-ui/react';
import { CheckCircle } from "../Config/icon";

const avatars = [
    {
        name: 'Ryan Florence',
        url: 'https://bit.ly/ryan-florence',
    },
    {
        name: 'Segun Adebayo',
        url: 'https://bit.ly/sage-adebayo',
    },
    {
        name: 'Kent Dodds',
        url: 'https://bit.ly/kent-c-dodds',
    },
    {
        name: 'Prosper Otemuyiwa',
        url: 'https://bit.ly/prosper-baba',
    },
    {
        name: 'Christian Nwamba',
        url: 'https://bit.ly/code-beast',
    },
];

function PriceWrapper({ children }) {
    return (
        <Box
            mb={4}
            maxW={'320px'}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

export default function Career() {
    return (
        <>
            <Box position={'relative'}>
                <Container
                    as={SimpleGrid}
                    maxW={'7xl'}
                    columns={{ base: 1, md: 2 }}
                    spacing={{ base: 10, lg: 32 }}
                    py={{ base: 10, sm: 20, lg: 32 }}>
                    <Stack spacing={{ base: 10, md: 20 }}>
                        <Heading
                            lineHeight={1.1}
                            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
                            Together at Triadic Solutions.{' '}
                            <Text
                                as={'span'}
                                bgClip="text">
                                color={'black'}
                                {'&'}
                            </Text>{' '}
                        </Heading>
                        <Stack direction={'row'} spacing={4} align={'center'}>
                            <AvatarGroup>
                                {avatars.map((avatar) => (
                                    <Avatar
                                        key={avatar.name}
                                        name={avatar.name}
                                        src={avatar.url}
                                        // size={useBreakpointValue({ base: 'md', md: 'lg' })}
                                        position={'relative'}
                                        zIndex={2}
                                        _before={{
                                            content: '""',
                                            width: 'full',
                                            height: 'full',
                                            rounded: 'full',
                                            transform: 'scale(1.125)',
                                            position: 'absolute',
                                            zIndex: -1,
                                            top: 0,
                                            left: 0,
                                        }}
                                    />
                                ))}
                            </AvatarGroup>
                            <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
                                +
                            </Text>
                            <Flex
                                align={'center'}
                                justify={'center'}
                                fontFamily={'heading'}
                                fontSize={{ base: 'sm', md: 'lg' }}
                                bg={'gray.800'}
                                color={'white'}
                                rounded={'full'}
                                width={useBreakpointValue({ base: '44px', md: '60px' })}
                                height={useBreakpointValue({ base: '44px', md: '60px' })}
                                position={'relative'}
                                _before={{
                                    content: '""',
                                    width: 'full',
                                    height: 'full',
                                    rounded: 'full',
                                    transform: 'scale(1.125)',
                                    position: 'absolute',
                                    zIndex: -1,
                                    top: 0,
                                    left: 0,
                                }}>
                                YOU
                            </Flex>
                        </Stack>
                    </Stack>
                    <Stack
                        bg={'gray.50'}
                        rounded={'xl'}
                        p={{ base: 4, sm: 6, md: 8 }}
                        spacing={{ base: 8 }}
                        maxW={{ lg: 'lg' }}>
                        <Stack spacing={4}>
                            <Heading
                                color={'gray.800'}
                                lineHeight={1.1}
                                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                                Join our team
                                <Text
                                    as={'span'}
                                    bgClip="text">
                                    !
                                </Text>
                            </Heading>
                            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                                We’re looking for amazing talanet just like you! Become a part
                                of our rockstar IT solution team and skyrocket your career!
                            </Text>
                        </Stack>
                        <Box as={'form'} mt={10}>
                            <Stack spacing={4}>
                                <Input
                                    placeholder="Firstname"
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                />
                                <Input
                                    placeholder="firstname@lastname.io"
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                />
                                <Input
                                    placeholder="+91 __________"
                                    bg={'gray.100'}
                                    border={0}
                                    color={'gray.500'}
                                    _placeholder={{
                                        color: 'gray.500',
                                    }}
                                />
                                <Button fontFamily={'heading'} bg={'gray.200'} color={'gray.800'}>
                                    Upload CV
                                </Button>
                            </Stack>
                            <Button
                                fontFamily={'heading'}
                                mt={8}
                                w={'full'}
                                colorScheme="teal"
                                variant="solid"
                                color={'white'}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Box py={12}>
                <VStack spacing={2} textAlign="center">
                    <Heading as="h1" fontSize="4xl">
                        Create Smart things with us!
                    </Heading>
                    <Text fontSize="lg" color={'gray.500'}>
                        We are always egaer to meet fresh talent, so check out our open positions.
                    </Text>
                </VStack>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    textAlign="center"
                    justify="center"
                    spacing={{ base: 4, lg: 10 }}
                    py={10}>
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Business Development Executive
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="md" fontWeight="600">
                                    Experience -
                                </Text>
                                <Text fontSize="md" fontWeight="900">
                                    2
                                </Text>
                                <Text fontSize="md" color="gray.500">
                                    Years
                                </Text>
                            </HStack>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 1
                                </ListItem>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 2
                                </ListItem>
                                <ListItem>
                                    <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                    Eligibility 3
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button w="full" colorScheme="teal">
                                    Apply Now
                                </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>

                    <PriceWrapper>
                        <Box position="relative">
                            <Box py={4} px={12}>
                                <Text fontWeight="500" fontSize="2xl">
                                    Front-End Developer
                                </Text>
                                <HStack justifyContent="center">
                                    <Text fontSize="md" fontWeight="600">
                                        Experience -
                                    </Text>
                                    <Text fontSize="md" fontWeight="900">
                                        2
                                    </Text>
                                    <Text fontSize="md" color="gray.500">
                                        Years
                                    </Text>
                                </HStack>
                            </Box>
                            <VStack
                                bg={useColorModeValue('gray.50', 'gray.700')}
                                py={4}
                                borderBottomRadius={'xl'}>
                                <List spacing={3} textAlign="start" px={12}>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 1
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 2
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon color="green.500"><CheckCircle size={'24px'} /></ListIcon>
                                        Eligibility 3
                                    </ListItem>
                                </List>
                                <Box w="80%" pt={7}>
                                    <Button w="full" colorScheme="teal">
                                        Apply Now
                                    </Button>
                                </Box>
                            </VStack>
                        </Box>
                    </PriceWrapper>
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                No Current Openings
                            </Text>
                        </Box>
                        <VStack
                            bg={useColorModeValue('gray.50', 'gray.700')}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    Currently we have no openings for the job positions. 
                                </ListItem>
                                <ListItem>Yet, keep looking for the opening; Else you can submit your CV/Resume.</ListItem>
                            </List>
                        </VStack>
                    </PriceWrapper>
                </Stack>
            </Box>
        </>
    );
}

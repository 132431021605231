import {
    Box,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    Flex,
    VStack,
    useBreakpointValue,
    useColorModeValue,
    Heading
} from '@chakra-ui/react';
import {
    ShoppingCart,
    Mobile,
    Website,
    Api,
    Design,
    Code,
    Crm,
    Payment,
    Html,
} from '../Config/icon';

const Feature = ({ title, text, icon, iconBg }) => {
    return (
        <Stack alignItems={'center'}>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={iconBg}
                mb={1}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text color={'gray.600'} textAlign={"justify"}>{text}</Text>
        </Stack>
    );
};

export default function Services() {
    return (
        <>
            <Flex
                w={'full'}
                h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                backgroundImage={
                    'url(https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80)'
                }
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                <VStack
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                    <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm: 'sm' })}>
                            WE ARE HEROS OF
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.1}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                            Design, Development, and Identify your product.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm: 'xs' })}>
                            Leading IT company with soild design and Development expertise.
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
            <Box pb={20} maxW={'7xl'} py={12} mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
                <Flex justifyContent={"center"} mb={10}>
                    <Stack spacing={4}>
                        <Heading>Our Service Stack</Heading>
                        <hr />
                    </Stack>
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 3 }} pt={4} spacing={10}>
                    <Feature
                        icon={<Icon as={ShoppingCart} color={'yellow.500'} size="20px" />}
                        iconBg={useColorModeValue('yellow.500', 'yellow.900')}
                        title={'E-Commerce Solution'}
                        text={'We fully understand that a powerful e-commerce website can serve as the foundation of your online business and understand the significance of using the latest technology to create stunning e-commerce solutions. Our custom e-commerce website design services offer the best in terms of standard features and functionality while still remaining completely customizable and agile.'}
                    />
                    <Feature
                        icon={<Icon as={Mobile} color={'green.500'} size="20px" />}
                        iconBg={useColorModeValue('green.500', 'green.900')}
                        title={'Mobile Application Development'}
                        text={'With regards to mobile application development, we accept that the customization choices which mobile appliaction platform give helps in making visually stunning and profoundly functional applications. We understand that similar looking applications on the application Store may effectively drive users away from your application. Thusly, we guarantee that they not just develop an incredible mobile application which stands the trial of time, but at the same time is reliable, useful, and customized to your necessities.'}
                    />
                    <Feature
                        icon={<Icon as={Website} color={'purple.500'} size="20px" />}
                        iconBg={useColorModeValue('purple.500', 'purple.900')}
                        title={'Web Development'}
                        text={'We make use of suitable technology stack for your business. We develop mobile responsive website sites correctly customized to your business need. We offer all round types of assistance in designing, developing and maintaining powerful web presence for your business.'}
                    />
                    <Feature
                        icon={<Icon as={Design} color={'yellow.500'} size="20px" />}
                        iconBg={useColorModeValue('blue.500', 'blue.900')}
                        title={'Web Design'}
                        text={'We make use of suitable font-end technology stack for your business need. We creates visually attractive websites that catches your brand, further develop your conversion rates, and boost your revenue to assist with developing your business and accomplish your business goals.'}
                    />
                    <Feature
                        icon={<Icon as={Api} color={'green.500'} size="20px" />}
                        iconBg={useColorModeValue('red.500', 'red.900')}
                        title={'API Integration'} 
                        text={'We are providing an API integration service that can cater to all your requirements with ease. We have some of the most qualified and skilled developers on board who can leverage the latest tools and technologies while delivering top-notch services to clients.'}
                    />
                    <Feature
                        icon={<Icon as={Code} color={'purple.500'} size="20px" />}
                        iconBg={useColorModeValue('cyan.500', 'cyan.900')}
                        title={'Website Redesign'}
                        text={'We help to transform a website\'s current look and feel with patial or complete revamping of UX and UI. We cover full life cycleof website redesign - including load speed optimization, content migration, and UI and UX audit.'}
                    />
                    <Feature
                        icon={<Icon as={Crm} color={'yellow.500'} size="20px" />}
                        iconBg={useColorModeValue('teal.500', 'teal.900')}
                        title={'Portal Development'}
                        text={'We offers skilled web-based portal solutions for associations enabling them to utilize the vast capability of the web for their business development. We offers portal design, development and portal maintenance services result in the creation of feature-rich web portals and industry specific portals.'}
                    />
                    <Feature
                        icon={<Icon as={Payment} color={'green.500'} size="20px" />}
                        iconBg={useColorModeValue('orange.500', 'orange.900')}
                        title={'Payment Gateway Integration'}
                        text={'We can assist you with incorporating different payment gateways into your online business stores to work with secure and straightforward online exchanges. We have broad involvement in payment gateways administrations and payment gateways execution and can assist you with payment gateways reconciliations for all the significant payment gateways specialist co-ops. Make getting payment from your clients a breeze by picking our payment gateways coordination administrations.'}
                    />
                    <Feature
                        icon={<Icon as={Html} color={'purple.500'} size="20px" />}
                        iconBg={useColorModeValue('pink.500', 'pink.900')}
                        title={'PSD to HTML Conversion'}
                        text={'We create design as per your pre-defined PSD file. We take care of all the CSS, design templates, and static elements. At the end we\'ll deliver you all the necessary files to have a responsive and functioning client-side website.'}
                    />
                </SimpleGrid>
            </Box>
        </>
    );
}
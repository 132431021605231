export const NAV_ITEMS = [
    {
        label: 'Home',
        href: '/',
    },
    {
        label: 'About',
        href: '/about',
    },
    {
        label: 'Services',
        href: '/services',
    },
    {
        label: 'Our Work',
        href: '/work',
    },
    {
        label: 'Career',
        href: '/career',
    },
    {
        label: 'Contact Us',
        href: '/contact',
    },
];

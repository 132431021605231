import React from "react";
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose, AiOutlineAntDesign, AiTwotoneApi, AiOutlineHtml5 } from "react-icons/ai";
import { BiChevronDown, BiChevronRight, BiCustomize, BiMailSend } from "react-icons/bi";
import { BsCodeSlash, BsPerson } from "react-icons/bs";
import { MdEmail, MdPhone, MdLocationOn, MdPayment } from 'react-icons/md';
import { HiClock } from 'react-icons/hi';
import { FaFacebookF, FaTwitter, FaDribbble, FaInstagram, FaLinkedinIn, FaRegSmile, FaCheckCircle } from 'react-icons/fa';
import { ImMobile } from 'react-icons/im';
import { CgWebsite, CgPerformance } from 'react-icons/cg';
import { GrServices } from 'react-icons/gr';
import { TiShoppingCart } from 'react-icons/ti';
import { SiCivicrm } from "react-icons/si";
import {RiGuideLine} from "react-icons/ri";
  
export const HamburgerMenu = (props) => <GiHamburgerMenu size={props.size ? props.size : "50px"}/>;
export const OutlineClose = (props) => <AiOutlineClose size={props.size ? props.size : "50px"}/>;
export const ChevronDown = (props) => <BiChevronDown size={props.size ? props.size : "50px"}/>;
export const ChevronRight = (props) => <BiChevronRight size={props.size ? props.size : "50px"}/>;
export const Email = (props) => <MdEmail size={props.size ? props.size : "50px"}/>;
export const Location = (props) => <MdLocationOn size={props.size ? props.size : "50px"}/>;
export const Phone = (props) => <MdPhone size={props.size ? props.size : "50px"}/>;
export const Clock = (props) => <HiClock size={props.size ? props.size : "50px"}/>;
export const Facebook = (props) => <FaFacebookF size={props.size ? props.size : "50px"}/>;
export const Twitter = (props) => <FaTwitter size={props.size ? props.size : "50px"}/>;
export const Dribbble = (props) => <FaDribbble size={props.size ? props.size : "50px"}/>;
export const Instagram = (props) => <FaInstagram size={props.size ? props.size : "50px"}/>;
export const LinkedinIn = (props) => <FaLinkedinIn size={props.size ? props.size : "50px"}/>;
export const Mobile = (props) => <ImMobile size={props.size ? props.size : "50px"}/>;
export const Website = (props) => <CgWebsite size={props.size ? props.size : "50px"}/>;
export const Services = (props) => <GrServices size={props.size ? props.size : "50px"}/>;
export const ShoppingCart = (props) => <TiShoppingCart size={props.size ? props.size : "50px"}/>;
export const Api = (props) => <AiTwotoneApi size={props.size ? props.size : "50px"}/>;
export const Design = (props) => <AiOutlineAntDesign size={props.size ? props.size : "50px"}/>;
export const Code = (props) => <BsCodeSlash size={props.size ? props.size : "50px"}/>;
export const Crm = (props) => <SiCivicrm size={props.size ? props.size : "50px"}/>;
export const Payment = (props) => <MdPayment size={props.size ? props.size : "50px"}/>;
export const Html = (props) => <AiOutlineHtml5 size={props.size ? props.size : "50px"}/>;
export const Custom = (props) => <BiCustomize size={props.size ? props.size : "50px"}/>;
export const Smile = (props) => <FaRegSmile size={props.size ? props.size : "50px"}/>;
export const Delivery = (props) => <RiGuideLine size={props.size ? props.size : "50px"}/>;
export const Person = (props) => <BsPerson size={props.size ? props.size : "50px"}/>;
export const Performance = (props) => <CgPerformance size={props.size ? props.size : "50px"}/>;
export const MailSend = (props) => <BiMailSend size={props.size ? props.size : "50px"}/>;
export const CheckCircle = (props) => <FaCheckCircle size={props.size ? props.size : "50px"}/>;

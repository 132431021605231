import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    Text,
    StatLabel,
    StatNumber,
    Container,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    Person,
    Delivery,
    Performance
} from "../../Config/icon";

function StatsCard(props) {
    const { title, stat, icon } = props;
    return (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'5'}
            shadow={'xl'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <Flex justifyContent={'space-between'}>
                <Box
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}>
                    {icon}
                </Box>
                <Box pl={{ base: 2, md: 4 }}>
                    <StatLabel fontSize={'1xl'} fontWeight={'bold'} isTruncated>
                        {title}  
                    </StatLabel>
                    <StatNumber fontSize={'md'} fontWeight={'light'}>
                        {stat}
                    </StatNumber>
                </Box>
            </Flex>
        </Stat>
    );
}

export default function HomePortfolio() {
    return (
        <Container maxW={'7xl'}>
            <Box pb={20} py={12} mx={'auto'} align={"center"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
                <chakra.h1
                    textAlign={'center'}
                    fontSize={'4xl'}
                    py={10}
                    mb={-10}
                    fontWeight={'bold'}>
                    Why work with us?
                </chakra.h1>
                <Text
                    textAlign={'center'}
                    fontSize={'1xl'}
                    py={5}
                    display={"flex"}
                    justifyContent={"center"}
                    fontWeight={'regular'}
                    maxW={"lg"} 
                    mb={5}
                >
                    We believe in execution rather than promises. Execution is the key and we use that effectively to craft your business ideas into digital solutions.
                </Text>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                    <StatsCard
                        title={'Dedicated Support'}
                        stat={'We are active in this field since so long so, we know the importance of right support.'}
                        icon={<Person size={'3em'} />}
                    />
                    <StatsCard
                        title={'Performance and Quality'}
                        stat={'We implement projects fully meeting our customer\'s quality, time, and budget expectations.'}
                        icon={<Performance size={"3em"} />}
                    />
                    <StatsCard
                        title={'Quick and Efficient Delivery'}
                        stat={'Our delivery standards and speed are unparalleled in the industry ensuring a smooth and rapid release.'}
                        icon={<Delivery size={"3em"} />}
                    />
                </SimpleGrid>
            </Box>
        </Container>
    );
}


import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import Home from './Components/Home';
import Header from "./Components/Header";
import Contact from "./Components/Contact";
import Services from "./Components/Services";
import About from "./Components/About";
import Work from "./Components/Work";
import Career from "./Components/Career";
import Footer from "./Components/Footer";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default function routes() {
    return (
        <Router history={history}>
            <React.Fragment>
                <Header history={history} />
                <div className="mainContainer">
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/services" component={Services} />
                        <Route exact path="/work" component={Work} />
                        <Route exact path="/career" component={Career} />
                        <Route exact path="/about" component={About} />
                    </Switch>
                    <Footer />
                </div>
            </React.Fragment>
        </Router>
    );
}
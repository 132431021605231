import {
    Box,
    Flex,
    VStack,
    useBreakpointValue,
    Heading,
    Text,
    Image,
    Stack,
    Container,
    useColorModeValue,
} from '@chakra-ui/react';
import RBKothari from "../assets/images/RBKothari.png";

export default function Work() {
    return (
        <>
            <Flex
                w={'full'}
                h={useBreakpointValue({ base: '100vh', md: '65vh', lg: '65vh' })}
                backgroundImage={
                    'url(https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80)'
                }
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                <VStack
                    w={'full'}
                    justify={'center'}
                    px={useBreakpointValue({ base: 4, md: 8 })}
                    bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                    <Stack h={'30vh'} maxW={'2xl'} align={'center'} spacing={6}>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: '1xl', sm: 'sm' })}>
                            WE ARE HEROS OF
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.1}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '4xl', md: '5xl', lg: '5xl' })}>
                            Design, Development, and Identify your product.
                        </Text>
                        <Text
                            color={'white'}
                            fontWeight={700}
                            lineHeight={1.2}
                            textAlign={'center'}
                            fontSize={useBreakpointValue({ base: '1xl', md: 'sm', lg: 'sm', sm: 'xs' })}>
                            Leading IT company with soild design and Development expertise.
                        </Text>
                    </Stack>
                </VStack>
            </Flex>
            <Container maxW={'7xl'} py={12}>
                <Flex mt={10} justifyContent={"center"} mb={10}>
                    <Stack>
                        <Heading>Our Work</Heading>
                        <hr />
                    </Stack>
                </Flex>
                <Stack py={6} display={"flex"} spacing={5} justifyContent={"center"} alignItems={"center"} direction={useBreakpointValue({ sm: "column", lg: "row" })}>
                    <Box
                        maxW={'445px'}
                        w={'full'}
                        bg={useColorModeValue('white', 'gray.900')}
                        boxShadow={'2xl'}
                        rounded={'md'}
                        p={6}
                        overflow={'hidden'}>
                        <Box
                            h={'210px'}
                            bg={'gray.100'}
                            mt={-6}
                            mx={-6}
                            mb={6}
                            pos={'relative'}>
                            <Image
                                src={RBKothari}
                                layout={'fill'}
                            />
                        </Box>
                        <Stack>
                            <Text
                                color={'green.500'}
                                textTransform={'uppercase'}
                                fontWeight={800}
                                fontSize={'sm'}
                                letterSpacing={1.1}>
                                Website
                            </Text>
                            <Heading
                                color={useColorModeValue('gray.700', 'white')}
                                fontSize={'2xl'}
                                fontFamily={'body'}>
                                R.B. Kothari Trust
                            </Heading>
                            <Text color={'gray.500'}>
                                We have designed and developed website for R.B. Kothari trust Rajkot. We have embedded a module in which medical reports of the patient stores in the website itself. 
                            </Text>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    );
}